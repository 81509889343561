import { template as template_93a622c24b914203a9ec9d0c5b7845be } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_93a622c24b914203a9ec9d0c5b7845be(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
