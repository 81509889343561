import { template as template_22c2193e51294d51b52e1f23eac7182f } from "@ember/template-compiler";
const FKControlMenuContainer = template_22c2193e51294d51b52e1f23eac7182f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
