import { template as template_c87d9209c5564ebbb9c6d55df60d8811 } from "@ember/template-compiler";
const FKLabel = template_c87d9209c5564ebbb9c6d55df60d8811(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
